// @flow
import * as React from 'react';
import Container from '../components/grid/container';
import DefaultLayout from '../layouts/default';
import Column from '../components/grid/column';
import Row from '../components/grid/row';
import Link from '../components/link';

export default () => (
  <DefaultLayout slim>
    <Container>
      <Row spaced={2}>
        <Column>
          <h1>Subscription Confirmed</h1>
          <p>
            You are all set to receive occasional development updates and announcements.
          </p>
          <p>
            <Link button to="/">Go Home</Link>
          </p>
        </Column>
      </Row>
    </Container>
  </DefaultLayout>
);
